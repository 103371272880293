import { Dialog } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { FC } from 'react'

interface InfoProps {
    title: string;
    message: string;
    buttonText: string;
    onClick: () => void;
}

const Info: FC<InfoProps> = ({ onClick, title, message, buttonText }) => {
    return (
        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
            <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                    
                    <Dialog.Title data-testid="title" as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        {title}
                    </Dialog.Title>
                    <div className="mt-2">
                        <p data-testid="message" className="text-sm text-gray-500">
                            {message}
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-5 sm:mt-6">
                <button
                    type="button"
                    data-testid="closeButton"
                    className="inline-flex justify-center w-full rounded-md bg-novo-blue p-4 mt-3 py-2 rounded-full text-center text-novo-white mb-8"
                    onClick={onClick}
                >
                    {buttonText}
                </button>
            </div>
        </div>
    )
}

export default Info;