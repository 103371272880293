import { API, graphqlOperation } from "aws-amplify";
import React, { useEffect, useState } from "react";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
interface UpdateServiceFormProps {
    serviceId?: any;
    service: any;
    accounts: any;
    userInfo: any;
    onCreated: () => void;
}

const UpdateServiceForm = ({ serviceId = null, service, accounts, userInfo, onCreated }: UpdateServiceFormProps) => {
    // const [activeServiceClass, setActiveServiceClass] = useState<null | string>(null)
    // const [activeServiceClassVersion, setActiveServiceClassVersion] = useState<null | string>(null)
    const [loading, setLoading] = useState(false);
    const [cloudAssemblies, setCloudAssemblies] = useState<any[]>([]);
    const [adGroups, setAdGroups] = useState<string[]>([]);
    const [selectedAdGroupsAdmin, setSelectedAdGroupsAdmin] = useState<string[]>([
        service.serviceConfiguration.adminAdGroup,
    ]);
    const [selectedAdGroupsEditor, setSelectedAdGroupsEditor] = useState<string[]>([
        service.serviceConfiguration.editorAdGroup,
    ]);
    const [selectedAdGroupsViewer, setSelectedAdGroupsViewer] = useState<string[]>([
        service.serviceConfiguration.viewerAdGroup,
    ]);
    const [formError, setFormError] = useState<null | string>(null);
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
    
    const serviceNameInput = React.useRef<any>(null);
    const assemblyVersionInput = React.useRef<any>(null);
    const serviceOwnerInput = React.useRef<any>(null);
    const gfImageVersionInput = React.useRef<any>({
        value: service.serviceConfiguration.imageVersion,
    });
    const gfPluginsInput = React.useRef<any>({
        value: service.serviceConfiguration.grafanaPlugins,
    });   
    const gfMinDashboardRefreshIntervalInput = React.useRef<any>(
        {value: service.serviceConfiguration.minDashboardRefreshInterval}
    );
    const gfMinUnifiedAlertingIntervalInput = React.useRef<any>(
        {value: service.serviceConfiguration.minUnifiedAlertingInterval}
    );
    
    // Initialize the allowEmbedding state based on the service configuration.
    const [allowEmbedding, setAllowEmbedding] = React.useState(service.serviceConfiguration.allowEmbedding || "false");

    // Update the allowEmbedding state when a radio button is selected.
    const handleAllowEmbeddingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAllowEmbedding(event.target.value);
    }

    const [azureAuthEnabled, setAzureAuthEnabled] = React.useState(service.serviceConfiguration.azureAuthEnabled || "false");

    const handleAzureAuthEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAzureAuthEnabled(event.target.value);
    }

    const gfLoginMaximumInactiveLifetimeDurationInput = React.useRef<any>(
        {value: service.serviceConfiguration.loginMaximumInactiveLifetimeDuration}
    );  
    const accountInput = React.useRef<any>(null);
    const advOptionsInput = React.useRef<any>(null);

    const activeServiceClass = "grafana";
    const activeServiceClassVersion = "1.0.0";
    useEffect(() => {
        if (userInfo["custom:groups"]) {
            setAdGroups(JSON.parse(userInfo["custom:groups"]));
        }
    }, [userInfo]);

    useEffect(() => {
        if (activeServiceClass && activeServiceClassVersion) {
            getcloudAssemblies(activeServiceClass, activeServiceClassVersion);
        }
    }, [activeServiceClass, activeServiceClassVersion]);

    const onFormSubmit = () => {
        setFormError(null);
        setLoading(true);
        if (validateInput()) {
            try {
                doProvisioning();
                onCreated();
            } catch (error: any) {
                console.log(error);
                setFormError(error);
            }
        }
        setLoading(false);
    };

    const getcloudAssemblies = async (serviceClassName: string, serviceClassVersion: string) => {
        setLoading(true);
        try {
            // Query using a parameter
            const response: any = await API.graphql({
                query: queries.listCloudAssemblies,
                variables: {
                    limit: 200,
                    serviceClassName: serviceClassName,
                    serviceClassVersion: serviceClassVersion,
                },
            });
            setLoading(false);
            const data = response.data["listCloudAssemblies"]["items"];

            const sorted = data.sort((a: any, b: any) => {
                const aParts = a.buildNumber.split('.').map(Number);
                const bParts = b.buildNumber.split('.').map(Number);
                for (let i = 0; i < aParts.length && i < bParts.length; i++) {
                    if (aParts[i] < bParts[i]) {
                        return -1;
                    } else if (aParts[i] > bParts[i]) {
                        return 1;
                    }
                }
                return aParts.length - bParts.length;
            }).reverse()
            setCloudAssemblies(sorted);
        } catch (error: any) {
            console.log("GRAPHQL ERROR");
            console.log(error.errors[0]);
            setLoading(false);
            setCloudAssemblies([]);
        }
    };

    const validateInput = () => {
        const serviceName = serviceNameInput.current.value;
        const serviceOwner = serviceOwnerInput.current.value;
        console.log(gfImageVersionInput);
        if (
            !serviceName ||
            !serviceOwner ||
            selectedAdGroupsAdmin.length === 0 ||
            selectedAdGroupsEditor.length === 0 ||
            selectedAdGroupsViewer.length === 0
        ) {
            setFormError("One or more fields are missing");
            return false;
        }
        if (
            selectedAdGroupsAdmin.length !== 1 ||
            selectedAdGroupsEditor.length !== 1 ||
            selectedAdGroupsViewer.length !== 1
        ) {
            setFormError("Only 1 AD group per role is allowed");
            return false;
        }
        if (!gfImageVersionInput.current) {
            gfImageVersionInput["current"] = { value: "latest" };
        } else if (!gfImageVersionInput.current.value) {
            gfImageVersionInput["current"] = { value: "latest" };
        }
        if (!gfPluginsInput.current) {
            gfPluginsInput["current"] = { value: "" };
        } else if (!gfPluginsInput.current.value) {
            gfPluginsInput["current"] = { value: "" };
        }
        if (!gfMinDashboardRefreshIntervalInput.current) {
            gfMinDashboardRefreshIntervalInput["current"] = { value: "5s" };
        } else if (!gfMinDashboardRefreshIntervalInput.current.value) {
            gfMinDashboardRefreshIntervalInput["current"] = { value: "5s" };
        }
        if (!gfMinUnifiedAlertingIntervalInput.current){
            gfMinUnifiedAlertingIntervalInput["current"] = { value: "10s" };
        } else if (!gfMinUnifiedAlertingIntervalInput.current.value) {
            gfMinUnifiedAlertingIntervalInput["current"] = { value: "10s" };
        }
        if (!gfLoginMaximumInactiveLifetimeDurationInput.current){
            gfLoginMaximumInactiveLifetimeDurationInput["current"] = { value: "7d" };
        } else if (!gfLoginMaximumInactiveLifetimeDurationInput.current.value) {
            gfLoginMaximumInactiveLifetimeDurationInput["current"] = { value: "7d" };
        }
        if (!userInfo["cost-center"]) {
            setFormError("Internal error, cost-center not part of token");
            return false;
        }
        if (!assemblyVersionInput.current.value) {
            setFormError("No assemblyVersion selected");
        }
        return true;
    };

    const doProvisioning = async () => {
        var provisioningInput: { [k: string]: any } = {};
        const serviceName = serviceNameInput.current.value;
        const serviceOwner = serviceOwnerInput.current.value;
        const imageVersion = gfImageVersionInput.current.value;
        const plugins = gfPluginsInput.current.value;
        const minDashboardRefreshInterval = gfMinDashboardRefreshIntervalInput.current.value;
        const minUnifiedAlertingInterval = gfMinUnifiedAlertingIntervalInput.current.value;
        const loginMaximumInactiveLifetimeDuration = gfLoginMaximumInactiveLifetimeDurationInput.current.value;
        const account = accountInput.current.value;
        const accountNumber = service.serviceConfiguration.targetAccount;
        const accountDomain = account.split(",")[2];
        const domainZoneId = account.split(",")[3];
        let region = "";
        if (service.serviceConfiguration.targetRegion === "eu-central-1") {
            region = "EU";
        } else if (service.serviceConfiguration.targetRegion === "us-east-1") {
            region = "US";
        }

        provisioningInput = {
            costCenter: userInfo["cost-center"],
            serviceOwner: serviceOwner,
            serviceName: serviceName,
            assemblyVersion: assemblyVersionInput.current.value,
            serviceClassName: activeServiceClass,
            serviceClassVersion: activeServiceClassVersion,
            region: region,
            adGroups: selectedAdGroupsAdmin.concat(selectedAdGroupsEditor.concat(selectedAdGroupsViewer)),
            serviceConfiguration: {
                targetAccount: accountNumber,
                accountDomain: accountDomain,
                domainZoneId: domainZoneId,
                adminAdGroup: selectedAdGroupsAdmin[0],
                editorAdGroup: selectedAdGroupsEditor[0],
                viewerAdGroup: selectedAdGroupsViewer[0],
                imageVersion: imageVersion,
                grafanaPlugins: plugins,
                minDashboardRefreshInterval: minDashboardRefreshInterval,
                minUnifiedAlertingInterval: minUnifiedAlertingInterval,
                allowEmbedding: allowEmbedding,
                azureAuthEnabled: azureAuthEnabled,
                loginMaximumInactiveLifetimeDuration: loginMaximumInactiveLifetimeDuration
            },
        };

        console.log(provisioningInput);
        return await API.graphql(
            graphqlOperation(mutations.startProvisioning, {
                input: provisioningInput,
            })
        );
    };

    const onSelectedADGroupsAdmin = (items: string[]) => {
        setSelectedAdGroupsAdmin(items);
        console.log(items);
    };

    const onSelectedADGroupsEditor = (items: string[]) => {
        setSelectedAdGroupsEditor(items);
        console.log(items);
    };

    const onSelectedADGroupsViewer = (items: string[]) => {
        setSelectedAdGroupsViewer(items);
        console.log(items);
    };

    const onSelectedAdvancedOptions = (items: any) => {
        setShowAdvancedOptions(advOptionsInput.current.checked);
    };

    if (loading) {
        return (
            // Loading spinner
            <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 mt-6 ml-6 w-12"></div>
            </div>
        );
    }
    return (
        // New service form
        <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
            <div className="px-4 sm:px-6">
                <h4>Update Service</h4>
                <p className="-mt-5">
                    {service.serviceName} {activeServiceClass} {activeServiceClassVersion}
                </p>
            </div>
            <div className="mt-6 relative flex-1 px-4 sm:px-6">
                <div className="absolute inset-0 px-4 sm:px-6">
                    <div className="grid grid-cols-1 gap-5">
                        <label className="block">
                            <span>Name</span>
                            <div className="help inline-block">
                                <span className="block bg-novo-blue text-gray-50 text-center ml-2 w-5 text-sm rounded-3xl opacity-75">
                                    ?
                                </span>
                                <span className="tooltip bg-gray-800 text-gray-50 text-sm px-3 py-1 rounded top-6 w-52">
                                    Name can contain only lower case alphanumeric characters and hyphens. It must start
                                    with an alphabetic character and can't be longer than 12 characters.
                                </span>
                            </div>
                            <input
                                readOnly
                                type="text"
                                ref={serviceNameInput}
                                className="mt-1 block w-full rounded-md border-gray-300 bg-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                placeholder=""
                                value={service.serviceName}
                                disabled
                            />
                        </label>

                        <label className="block">
                            <span>Build number</span>
                            <select
                                ref={assemblyVersionInput}
                                className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                value={service.serviceConfiguration.assemblyVersion}
                                onChange={(event) => {
                                    service.serviceConfiguration.assemblyVersion = event.target.value;
                                }}
                            >
                                {cloudAssemblies.map((assembly: any) => {
                                    return (
                                        <option
                                            key={assembly.buildNumber}
                                            value={assembly.buildNumber}
                                            selected={service.serviceConfiguration.assemblyVersion}
                                        >
                                            {assembly.buildNumber}
                                        </option>
                                    );
                                })}
                            </select>
                        </label>
                        <span>
                            <span>Admin AD Group</span>
                            <input
                                readOnly
                                type="text"
                                className="mt-1 block w-full rounded-md border-gray-300 bg-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                placeholder=""
                                value={service.serviceConfiguration.adminAdGroup}
                                disabled
                            />
                        </span>

                        <span>
                            <span>Editor AD Groups</span>
                            <input
                                readOnly
                                type="text"
                                className="mt-1 block w-full rounded-md border-gray-300 bg-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                placeholder=""
                                value={service.serviceConfiguration.editorAdGroup}
                                disabled
                            />
                        </span>

                        <span>
                            <span>Viewer AD Groups</span>
                            <input
                                readOnly
                                type="text"
                                className="mt-1 block w-full rounded-md border-gray-300 bg-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                placeholder=""
                                value={service.serviceConfiguration.viewerAdGroup}
                                disabled
                            />
                        </span>
                        <label className="block">
                            <span>Service Owner</span>
                            <input
                                type="text"
                                ref={serviceOwnerInput}
                                className="mt-1 block w-full rounded-md border-gray-300 bg-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                placeholder=""
                                value={userInfo.email.split("@")[0]}
                                disabled
                            />
                        </label>
                        <label className="block">
                            <span>Account</span>

                            {accounts.map((account: any) => {
                                if (
                                    account.accountNumber === service.serviceConfiguration.targetAccount &&
                                    account.accountRegion === service.serviceConfiguration.targetRegion
                                ) {
                                    return (
                                        <input
                                            type="text"
                                            className="mt-1 block w-full rounded-md border-gray-300 bg-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                            key={account.name}
                                            value={account.name}
                                            disabled
                                        />
                                    );
                                }
                            })}
                            {accounts.map((account: any) => {
                                if (
                                    account.accountNumber === service.serviceConfiguration.targetAccount &&
                                    account.accountRegion === service.serviceConfiguration.targetRegion
                                ) {
                                    return (
                                        <input
                                            type="hidden"
                                            ref={accountInput}
                                            key={account.name}
                                            value={[
                                                account.accountNumber,
                                                account.accountRegion,
                                                account.domainName,
                                                account.hostedZoneId,
                                            ]}
                                        />
                                    );
                                }
                            })}
                        </label>
                        <div>
                            <label className="inline-flex items-center">
                                <input
                                    ref={advOptionsInput}
                                    id="advOptions"
                                    name="advOptions"
                                    type="checkbox"
                                    onChange={onSelectedAdvancedOptions}
                                    className="rounded-full border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                />
                                <span className="ml-2">Show Advanced Options</span>
                            </label>
                        </div>
                        {showAdvancedOptions && (
                            <label className="block">
                                <span>Image Version</span>
                                <div className="help inline-block">
                                    <span className="block bg-novo-blue text-gray-50 text-center ml-2 w-5 text-sm rounded-3xl opacity-75">
                                        ?
                                    </span>
                                    <span className="tooltip bg-gray-800 text-gray-50 text-sm px-3 py-1 rounded top-6 w-52">
                                        Docker image version to be installed
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    ref={gfImageVersionInput}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    defaultValue={service.serviceConfiguration.imageVersion}
                                />
                            </label>
                        )}
                        {showAdvancedOptions && (
                            <label className="block">
                                <span>Plugins</span>
                                <div className="help inline-block">
                                    <span className="block bg-novo-blue text-gray-50 text-center ml-2 w-5 text-sm rounded-3xl opacity-75">
                                        ?
                                    </span>
                                    <span className="tooltip bg-gray-800 text-gray-50 text-sm px-3 py-1 rounded top-6 w-52">
                                        Comma separated plugins, that should be installed on Grafana
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    ref={gfPluginsInput}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    defaultValue={service.serviceConfiguration.grafanaPlugins}
                                />
                            </label>
                        )}
                        {showAdvancedOptions && (
                            <label className="block">
                                <span>Min Dashboard Refresh Interval</span>
                                <div className="help inline-block">
                                    <span className="block bg-novo-blue text-gray-50 text-center ml-2 w-5 text-sm rounded-3xl opacity-75">
                                        ?
                                    </span>
                                    <span className="tooltip bg-gray-800 text-gray-50 text-sm px-3 py-1 rounded top-6 w-52">
                                        Ex: 5s,10s,1m,15m,2h
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    ref={gfMinDashboardRefreshIntervalInput}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    defaultValue={service.serviceConfiguration.minDashboardRefreshInterval}
                                />
                            </label>
                        )} 
                        {showAdvancedOptions && (
                            <label className="block">
                                <span>Min Unified Alerting Interval</span>
                                <div className="help inline-block">
                                    <span className="block bg-novo-blue text-gray-50 text-center ml-2 w-5 text-sm rounded-3xl opacity-75">
                                        ?
                                    </span>
                                    <span className="tooltip bg-gray-800 text-gray-50 text-sm px-3 py-1 rounded top-6 w-52">
                                        Ex: 10s,1m,15m,2h
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    ref={gfMinUnifiedAlertingIntervalInput}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    defaultValue={service.serviceConfiguration.minUnifiedAlertingInterval}
                                />
                            </label>
                        )}
                        {showAdvancedOptions && (
                            <label className="block">
                                <span>Max Login Inactive Lifetime Duration</span>
                                <div className="help inline-block">
                                    <span className="block bg-novo-blue text-gray-50 text-center ml-2 w-5 text-sm rounded-3xl opacity-75">
                                        ?
                                    </span>
                                    <span className="tooltip bg-gray-800 text-gray-50 text-sm px-3 py-1 rounded top-6 w-52">
                                    Ex: 5m,6h,5d,2w,1M
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    ref={gfLoginMaximumInactiveLifetimeDurationInput}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    defaultValue={service.serviceConfiguration.loginMaximumInactiveLifetimeDuration}
                                />
                            </label>
                        )}
                        {showAdvancedOptions && (
                            <fieldset className="block">
                                <legend className="block">
                                <span>Allow Security Embedding</span>
                                <div className="help inline-block">
                                    <span className="block bg-novo-blue text-gray-50 text-center ml-2 w-5 text-sm rounded-3xl opacity-75">
                                    ?
                                    </span>
                                    <span className="tooltip bg-gray-800 text-gray-50 text-sm px-3 py-1 rounded top-6 w-52">
                                    Set to true to enable the embedding of Grafana dashboards, for more flexibility in sharing your data visualizations.
                                    </span>
                                </div>
                                </legend>
                            <div className="mt-1">
                                <label className="inline-flex items-center">
                                    <input
                                    type="radio"
                                    name="allowEmbedding"
                                    className="form-radio"
                                    value="false"
                                    checked={allowEmbedding === "false"}
                                    onChange={handleAllowEmbeddingChange}
                                    />
                                    <span className="ml-2">False (default)</span>
                                </label>
                                <label className="inline-flex items-center ml-6">
                                    <input
                                    type="radio"
                                    name="allowEmbedding"
                                    className="form-radio"
                                    value="true"
                                    checked={allowEmbedding === "true"}
                                    onChange={handleAllowEmbeddingChange}
                                    
                                    />
                                    <span className="ml-2">True</span>
                                </label>
                            </div>
                            </fieldset>
                        )}  
                        {showAdvancedOptions && (
                            <fieldset className="block">
                                <legend className="block">
                                <span>Enable Azure AD Authentication</span>
                                <div className="help inline-block">
                                    <span className="block bg-novo-blue text-gray-50 text-center ml-2 w-5 text-sm rounded-3xl opacity-75">
                                    ?
                                    </span>
                                    <span className="tooltip bg-gray-800 text-gray-50 text-sm px-3 py-1 rounded top-6 w-52">
                                    Set to true to enable Azure AD authentication.
                                    </span>
                                </div>
                                </legend>
                            <div className="mt-1">
                                <label className="inline-flex items-center">
                                    <input
                                    type="radio"
                                    name="azureAuthEnabled"
                                    className="form-radio"
                                    value="false"
                                    checked={azureAuthEnabled === "false"}
                                    onChange={handleAzureAuthEnabledChange}
                                    />
                                    <span className="ml-2">False (default)</span>
                                </label>
                                <label className="inline-flex items-center ml-6">
                                    <input
                                    type="radio"
                                    name="azureAuthEnabled"
                                    className="form-radio"
                                    value="true"
                                    checked={azureAuthEnabled === "true"}
                                    onChange={handleAzureAuthEnabledChange}
                                    />
                                    <span className="ml-2">True</span>
                                </label>
                            </div>
                            </fieldset>
                        )}                        
                        
                        <p className="text-red-600">{formError}</p>
                        <button
                            onClick={() => {
                                onFormSubmit();
                            }}
                            className="bg-novo-blue p-4 mt-3 py-2 rounded-full text-center text-novo-white mb-8"
                        >
                            Update service
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateServiceForm;
