import React from "react";
// import { useState } from "react";
import databricksLogo from "../assets/images/databricks.logo.png";
import dominoLogo from "../assets/images/domino.logo.png";
import datarobotLogo from "../assets/images/datarobot.logo.png";
import neo4jLogo from "../assets/images/neo4j.logo.png";
import ohdsiLogo from "../assets/images/ohdsi.logo.png";
import rstudioLogo from "../assets/images/rstudio.logo.png";
import connectLogo from "../assets/images/rstudioconnect.logo.png";
import sasviyaLogo from "../assets/images/sasviya.logo.png";
import jupyterLogo from "../assets/images/jupyterlab.logo.png";
import grafanaLogo from "../assets/images/grafana.logo.png";
// import { userInfo } from "os";
import { ReactComponent as GearIcon } from "../assets/icons/icon.gear.svg";

interface ServiceItemProps {
    service: any;
    userInfo: any;
    overlay: any;
    setService: any;
}

const ServiceItem = ({ service, userInfo, overlay, setService }: ServiceItemProps) => {
    const logoMap: Record<string, any> = {
        "rstudio-server": rstudioLogo,
        Databricks: databricksLogo,
        Neo4j: neo4jLogo,
        Ohdsi: ohdsiLogo,
        Sasviya: sasviyaLogo,
        Domino: dominoLogo,
        DataRobot: datarobotLogo,
        "rstudio-connect": connectLogo,
        jupyterlab: jupyterLogo,
        grafana: grafanaLogo,
    };


    const logoMapper = (serviceClass: string): any => logoMap[serviceClass] || "";
    const logo = logoMapper(service.serviceClass);

    return (
            <div
                key = {service.id}
                className={`rounded-md border bg-white shadow-md w-full mr-4 min-h-4 relative mb-2 ${
                    service.hidden ? "opacity-80" : ""
                } ${service.status === "provisioning" ? "pulse" : ""}`}
            >
                <div
                className={`w-full h-14 p-3 flex items-center whitespace-nowrap overflow-hidden rounded-t-md ${
                    service.hidden ? "bg-gray-600" : service.status === "provisioning" ? "bg-yellow-400" : "bg-service-active"
                } bg-opacity-20`}
                >
            
            <div className="flex-shrink-0">
                <img src={logo} alt="" className="max-w-16 max-h-28 object-contain mr-4" />
            </div>
            <div className="flex-grow">
                {service.hidden || service.status === "provisioning" ? (
                <div className="text-xl text">{service.serviceName}</div>
                ) : (
                <div className="text-xl text hover:underline">
                    <a href={service.appURL} target="_blank" rel="noreferrer">
                    {service.serviceName}
                    </a>
                </div>
                )}
            </div>
            {service.systemOwner === userInfo.email.split("@")[0] && service.serviceClass === "grafana" && (
                <div className="ml-2">
                <button
                    onClick={() => {
                    overlay(true);
                    setService(service);
                    }}
                    className="text-center text-novo-blue"
                >
                    <GearIcon className="w-5 h-5" />
                </button>
                </div>
            )}
            

            </div>

            <div className="w-full p-3 pt-2 rounded-b-md">
                <div className="text-sm mb-2">
                    <span className="mr-5">Status: {service.status}</span>
                </div>
                <div className="text-sm mb-2">
                    <span className="mr-5">Region: {service.region}</span>
                </div>
                
                <div className="text-sm mb-2 flex items-cen"> 
                    <span className="mr-5">Owner: {service.systemOwner} </span> 

                </div>
                <div className="text-sm mb-2">
                    <span className="mr-5">{service.description}</span>
                </div>
            </div>
        </div>
    );
};

export default ServiceItem;
