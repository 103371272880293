import React from "react";

type MyRolesProps = {
    roles: any[];
};

const MyRoles = ({ roles }: MyRolesProps) => {
    console.log(roles);

    return (
        <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
            <div className="px-4 sm:px-6">
                <h4>My Roles</h4>
                <p className="-mt-5">{}</p>
            </div>
            <div className="mt-6 relative flex-1 px-4 sm:px-6">
                <div className="absolute inset-0 px-4 sm:px-6">
                    <div className="grid grid-cols-1 gap-5">
                        <table className="table-auto">
                            <tbody>
                                {roles.map((role, index) => (
                                    <tr key={index}>
                                        <td className="border-b border-gray-700 pt-2 pb-2 text-sm text-black">
                                            {role}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyRoles;
