import { GraphQLResult } from "@aws-amplify/api-graphql";
import { API, graphqlOperation } from "aws-amplify";

export async function callGraphQL<T>(query: any, variables?: any): Promise<GraphQLResult<T>> {
    return (await API.graphql(graphqlOperation(query, variables))) as GraphQLResult<T>;
}

export * from "./useGetCloudAssemblies";
export * from "./useGetServiceClasses";
export * from "./useGetMyServices";
export * from "./useGetServiceAccounts";
export * from "./useStoreSession";
