import { Auth } from "aws-amplify";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import fullLogo from "../assets/images/logo.big.white.png";
import { ReactComponent as Logo } from "../assets/images/logo.small.svg";

interface SigninProps {
    amplifyConfigured: boolean;
    userInfo: any;
    errorMsg: string;
    setErrorMsg: any;
}

// FOR TESTING WITH SELF HOSTED LOGIN-FLOW, se git log

const Signin: FC<SigninProps> = ({ amplifyConfigured, userInfo, errorMsg, setErrorMsg }: SigninProps) => {
    let history = useNavigate();
    const loginRef = React.useRef<HTMLInputElement>(null);
    const passwordRef = React.useRef<HTMLInputElement>(null);
    const [loginLoading, setLoginLoading] = useState(false);

    useEffect(() => {
        if (userInfo) {
            history("/services");
        }
    }, [userInfo, history]);
    async function signIn(username: string, password: string) {
        Auth.federatedSignIn({ customProvider: "Oktao2-core" })
            .then((cred) => {
                // If success, you will get the AWS credentials
                return Auth.currentAuthenticatedUser();
            })
            .then((user) => {
                // If success, the user object you passed in Auth.federatedSignIn
                console.log(user);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    const doSignIn = () => {
        setErrorMsg(null);
        signIn(loginRef.current?.value || "", passwordRef.current?.value || "");
    };

    const onFormSubmit = (e: any) => {
        setLoginLoading(true);
        e.preventDefault();
        doSignIn();
    };

    return (
        <div className="bg-novo-blue min-h-screen pt-20 pb-6 px-2 justify-center items-center flex">
            <Logo className="absolute left-5 top-5 h-8 w-8" />
            <header className="max-w-xl mx-auto text-center self-auto bg-white w-2/4 rounded p-4 min-h-l">
                <img src={fullLogo} alt="Oasis" className="h-20 mx-auto"></img>
                <p className="text-sm">Sign in to your account</p>

                {!amplifyConfigured && (
                    <div className="py-2 px-5 mx-auto text-center mt-32">
                        <svg
                            className="m-auto animate-spin h-5 w-5 text-black"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                    </div>
                )}
                {amplifyConfigured && (
                    <form className="pt-12 block" onSubmit={onFormSubmit}>
                        <button
                            type="submit"
                            className="py-2 px-5 mb-6 mx-auto mt-20 font-semibold rounded-md text-gray-100 bg-novo-blue"
                        >
                            {loginLoading ? "Please wait..." : "Log in"}
                        </button>
                        <p className="text-novo-red text-xs italic block w-full h-4">{errorMsg}</p>
                    </form>
                )}
            </header>
        </div>
    );
};
export default Signin;
