import { Transition } from "@headlessui/react";
import React, { FC } from "react";
import { ReactComponent as CloseBtn } from "../assets/icons/close.btn.svg";

interface SlideOverlayProps {
    isOpen: boolean;
    onClose: () => void;
	children: React.ReactNode;
}

const SlideOverlay: FC<SlideOverlayProps> = ({ isOpen, onClose, children }) => {
    return (
        <Transition show={isOpen}>
            <div className="fixed inset-0 overflow-hidden text-novo-blue">
                <div className="absolute inset-0 overflow-hidden">
                    <Transition.Child
                        enter="ease-in-out duration-900"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-900"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div
                            className="absolute inset-0 bg-gray-500 bg-opacity-75"
                            // onClick={onClose}
                            aria-hidden="true"
                        ></div>
                    </Transition.Child>

                    <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex">
                        <Transition.Child
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="relative w-screen max-w-md h-full">
                                <Transition.Child
                                    enter="ease-in-out duration-500"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-500"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                                        <button
                                            className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                            onClick={onClose}
                                        >
                                            <span className="sr-only">Close panel</span>
                                            <CloseBtn />
                                        </button>
                                    </div>
                                </Transition.Child>
                                {children}
                            </div>
                        </Transition.Child>
                    </section>
                </div>
            </div>
        </Transition>
    );
};

export default SlideOverlay;
