import React, { useState } from "react";
import userIcon from "../assets/icons/icon.user.png";

type HeaderProps = {
	name: string,
	doSignOut: () => void,
	openMyRoles: () => void
	openFeatures: () => void
};

const Header = ({ name, doSignOut, openMyRoles, openFeatures }: HeaderProps) => {
	const [showDropdown, setShowDropdown] = useState(false);
	const toggleDropdown = () => {
		setShowDropdown(!showDropdown);
	};

	return (
		<header className="w-full items-center bg-white py-2 px-6 flex">
			<div className="w-1/2"></div>
			<div className="relative w-1/2 flex justify-end">
				<button onClick={toggleDropdown} className="cursor-pointer text-novo-light-blue">
					<img src={userIcon} alt="User" className="h-4 inline-block mr-2 mx-auto align-middle"></img> <p className="inline-block align-middle">{name}</p>
				</button>
				{showDropdown && (
					<button
						onClick={toggleDropdown}
						className="h-full w-full fixed inset-0 cursor-default outline-none"
					></button>
				)} 
				{showDropdown && (
					<div className="absolute w-34 bg-white rounded-lg shadow-lg mt-8 top-1 -right-1 z-10">
						<button onClick={(e) => { e.preventDefault(); window.location.href='https://docs.o2.data.novonordisk.cloud'; toggleDropdown(); }} className="block px-4 py-2 account-link rounded-t-lg hover:bg-gray-100">
							Documentation
						</button>
						<button onClick={(e) => { e.preventDefault(); openMyRoles(); toggleDropdown(); }} className="block px-4 py-2 account-link hover:bg-gray-100">
							My Roles
						</button>
						<button onClick={(e) => { e.preventDefault(); openFeatures(); toggleDropdown(); }} className="block px-4 py-2 account-link hover:bg-gray-100">
							Features
						</button>						
						<button
							onClick={() => doSignOut()}
							className="block px-4 py-2 rounded-b-lg account-link hover:bg-gray-100"
						>
							Sign Out
						</button>
					</div>
				)}
			</div>
		</header>
	);

};

export default Header;
