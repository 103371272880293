/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const health = /* GraphQL */ `
  query Health {
    health
  }
`;
export const status = /* GraphQL */ `
  query Status {
    status
  }
`;
export const listServiceClasses = /* GraphQL */ `
  query ListServiceClasses($limit: Int, $nextToken: String) {
    listServiceClasses(limit: $limit, nextToken: $nextToken) {
      items {
        name
        version
        displayName
        description
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getServiceClass = /* GraphQL */ `
  query GetServiceClass($name: String!, $version: String!) {
    getServiceClass(name: $name, version: $version) {
      name
      version
      displayName
      description
      __typename
    }
  }
`;
export const listCloudAssemblies = /* GraphQL */ `
  query ListCloudAssemblies(
    $serviceClassName: String!
    $serviceClassVersion: String!
    $limit: Int
    $nextToken: String
  ) {
    listCloudAssemblies(
      serviceClassName: $serviceClassName
      serviceClassVersion: $serviceClassVersion
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        serviceClassName
        serviceClassVersion
        buildNumber
        displayName
        description
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSystemRole = /* GraphQL */ `
  query GetSystemRole($input: GetSystemRoleInput!) {
    getSystemRole(input: $input) {
      id
      system
      roleName
      roleArn
      aDGroupAuth
      __typename
    }
  }
`;
export const getSystemRoles = /* GraphQL */ `
  query GetSystemRoles($ids: [ID]!) {
    getSystemRoles(ids: $ids) {
      id
      system
      roleName
      roleArn
      aDGroupAuth
      __typename
    }
  }
`;
export const getService = /* GraphQL */ `
  query GetService($input: GetServiceInput!) {
    getService(input: $input) {
      id
      serviceName
      serviceClass
      appURL
      createdAt
      idpArn
      systemOwner
      costCenter
      status
      region
      description
      aDGroupAuth
      authType
      authInfo {
        IdPName
        SAMLXML
        providerURL
        audience
        __typename
      }
      serviceConfiguration {
        targetAccount
        targetRegion
        accountDomain
        domainZoneId
        adminAdGroup
        editorAdGroup
        viewerAdGroup
        imageVersion
        grafanaPlugins
        minDashboardRefreshInterval
        minUnifiedAlertingInterval
        allowEmbedding
        azureAuthEnabled
        loginMaximumInactiveLifetimeDuration
        __typename
      }
      __typename
    }
  }
`;
export const getServices = /* GraphQL */ `
  query GetServices($ids: [ID]!) {
    getServices(ids: $ids) {
      id
      serviceName
      serviceClass
      appURL
      createdAt
      idpArn
      systemOwner
      costCenter
      status
      region
      description
      aDGroupAuth
      authType
      authInfo {
        IdPName
        SAMLXML
        providerURL
        audience
        __typename
      }
      serviceConfiguration {
        targetAccount
        targetRegion
        accountDomain
        domainZoneId
        adminAdGroup
        editorAdGroup
        viewerAdGroup
        imageVersion
        grafanaPlugins
        minDashboardRefreshInterval
        minUnifiedAlertingInterval
        allowEmbedding
        azureAuthEnabled
        loginMaximumInactiveLifetimeDuration
        __typename
      }
      __typename
    }
  }
`;
export const getAuthObject = /* GraphQL */ `
  query GetAuthObject($input: GetAuthObjectInput!) {
    getAuthObject(input: $input) {
      id
      authType
      authName
      __typename
    }
  }
`;
export const getAuthObjects = /* GraphQL */ `
  query GetAuthObjects($ids: [ID]!) {
    getAuthObjects(ids: $ids) {
      id
      authType
      authName
      __typename
    }
  }
`;
export const getMyServices = /* GraphQL */ `
  query GetMyServices($input: [ID]!) {
    getMyServices(input: $input)
  }
`;
export const getSystemRolesFromAuthObject = /* GraphQL */ `
  query GetSystemRolesFromAuthObject($id: ID!) {
    getSystemRolesFromAuthObject(id: $id) {
      id
      system
      roleName
      roleArn
      aDGroupAuth
      __typename
    }
  }
`;
export const getSystemRolesFromAuthObjectIds = /* GraphQL */ `
  query GetSystemRolesFromAuthObjectIds(
    $ids: [ID]!
    $limit: Int
    $nextToken: String
  ) {
    getSystemRolesFromAuthObjectIds(
      ids: $ids
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        system
        roleName
        roleArn
        aDGroupAuth
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getServicesFromAuthObject = /* GraphQL */ `
  query GetServicesFromAuthObject($id: ID!) {
    getServicesFromAuthObject(id: $id) {
      id
      serviceName
      serviceClass
      appURL
      createdAt
      idpArn
      systemOwner
      costCenter
      status
      region
      description
      aDGroupAuth
      authType
      authInfo {
        IdPName
        SAMLXML
        providerURL
        audience
        __typename
      }
      serviceConfiguration {
        targetAccount
        targetRegion
        accountDomain
        domainZoneId
        adminAdGroup
        editorAdGroup
        viewerAdGroup
        imageVersion
        grafanaPlugins
        minDashboardRefreshInterval
        minUnifiedAlertingInterval
        allowEmbedding
        azureAuthEnabled
        loginMaximumInactiveLifetimeDuration
        __typename
      }
      __typename
    }
  }
`;
export const getServicesFromAuthObjectIds = /* GraphQL */ `
  query GetServicesFromAuthObjectIds(
    $ids: [ID]!
    $limit: Int
    $nextToken: String
  ) {
    getServicesFromAuthObjectIds(
      ids: $ids
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serviceName
        serviceClass
        appURL
        createdAt
        idpArn
        systemOwner
        costCenter
        status
        region
        description
        aDGroupAuth
        authType
        authInfo {
          IdPName
          SAMLXML
          providerURL
          audience
          __typename
        }
        serviceConfiguration {
          targetAccount
          targetRegion
          accountDomain
          domainZoneId
          adminAdGroup
          editorAdGroup
          viewerAdGroup
          imageVersion
          grafanaPlugins
          minDashboardRefreshInterval
          minUnifiedAlertingInterval
          allowEmbedding
          azureAuthEnabled
          loginMaximumInactiveLifetimeDuration
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listSystemRoles = /* GraphQL */ `
  query ListSystemRoles($limit: Int, $nextToken: String) {
    listSystemRoles(limit: $limit, nextToken: $nextToken) {
      items {
        id
        system
        roleName
        roleArn
        aDGroupAuth
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listServices = /* GraphQL */ `
  query ListServices($limit: Int, $nextToken: String) {
    listServices(limit: $limit, nextToken: $nextToken) {
      items {
        id
        serviceName
        serviceClass
        appURL
        createdAt
        idpArn
        systemOwner
        costCenter
        status
        region
        description
        aDGroupAuth
        authType
        authInfo {
          IdPName
          SAMLXML
          providerURL
          audience
          __typename
        }
        serviceConfiguration {
          targetAccount
          targetRegion
          accountDomain
          domainZoneId
          adminAdGroup
          editorAdGroup
          viewerAdGroup
          imageVersion
          grafanaPlugins
          minDashboardRefreshInterval
          minUnifiedAlertingInterval
          allowEmbedding
          azureAuthEnabled
          loginMaximumInactiveLifetimeDuration
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listAuthObjects = /* GraphQL */ `
  query ListAuthObjects($limit: Int, $nextToken: String) {
    listAuthObjects(limit: $limit, nextToken: $nextToken) {
      items {
        id
        authType
        authName
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEdgesOfAuthObjects = /* GraphQL */ `
  query GetEdgesOfAuthObjects($limit: Int, $nextToken: String) {
    getEdgesOfAuthObjects(limit: $limit, nextToken: $nextToken) {
      items {
        AuthObjectId
        TargetId
        TargetType
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const querySystemRolesByType = /* GraphQL */ `
  query QuerySystemRolesByType(
    $after: String
    $first: Int
    $nodeType: String!
  ) {
    querySystemRolesByType(after: $after, first: $first, nodeType: $nodeType) {
      items {
        id
        system
        roleName
        roleArn
        aDGroupAuth
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listServiceAccounts = /* GraphQL */ `
  query ListServiceAccounts($limit: Int, $nextToken: String) {
    listServiceAccounts(limit: $limit, nextToken: $nextToken) {
      items {
        name
        accountNumber
        accountRegion
        displayName
        description
        domainName
        domainNameServers
        hostedZoneId
        corpConnected
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listCorpConnectedAccounts = /* GraphQL */ `
  query ListCorpConnectedAccounts($limit: Int, $nextToken: String) {
    listCorpConnectedAccounts(limit: $limit, nextToken: $nextToken) {
      items {
        name
        accountNumber
        accountRegion
        displayName
        description
        domainName
        domainNameServers
        hostedZoneId
        corpConnected
        __typename
      }
      nextToken
      __typename
    }
  }
`;
