import React from "react";

type FeaturesProps = {
    features: any[];
};

const Features = ({}: FeaturesProps) => {
    return (
        <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
            <div className="px-4 sm:px-6">
                <h4>Features</h4>
                <p className="-mt-5">{}</p>
            </div>
            <div className="mt-6 relative flex-1 px-4 sm:px-6">
                <div className="absolute inset-0 px-4 sm:px-6">
                    <div className="grid grid-cols-1 gap-5">
                        <table className="table-auto">
                            <tbody>Feature switches will appear here..</tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Features;
