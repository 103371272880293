import { GraphQLResult } from "@aws-amplify/api-graphql";
import { useEffect, useState } from "react";
import {ListCorpConnectedAccountsQuery, ServiceAccount } from "../../graphql/API";
import {listCorpConnectedAccounts} from "../../graphql/queries";
import { callGraphQL } from "./index";
 
function mapServiceAccounts(
    listServiceAccountResult: GraphQLResult<ListCorpConnectedAccountsQuery>): ServiceAccount[] {
    const response = listServiceAccountResult.data?.listCorpConnectedAccounts?.items?.filter((n) => n);

    return (
        response!.map(
            (serviceAccount) =>
                ({
                    name: serviceAccount?.name,
                    accountNumber: serviceAccount?.accountNumber,
                    accountRegion: serviceAccount?.accountRegion,
                    domainName: serviceAccount?.domainName,
                    domainNameServers: serviceAccount?.domainNameServers,
                    hostedZoneId: serviceAccount?.hostedZoneId,
                    description: serviceAccount?.description,
                    displayName: serviceAccount?.displayName,
                } as ServiceAccount)
        ) || []
    );
}

export function useGetServiceAccounts() {
    const [error, setError] = useState<Error>();
    const [isLoading, setIsLoading] = useState(false);
    const [serviceAccounts, setServiceAccounts] = useState<ServiceAccount[]>([]);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const data = await callGraphQL<ListCorpConnectedAccountsQuery>(listCorpConnectedAccounts);
            setServiceAccounts(mapServiceAccounts(data));
        } catch (e) {
            setError(e as Error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);
    return { serviceAccounts, isLoading, error, fetchData };
}
