import { GraphQLResult } from "@aws-amplify/api-graphql";
import { useEffect } from "react";
import { StoreSessionMutation, StoreSessionMutationVariables } from "../../graphql/API";
import { storeSession } from "../../graphql/mutations";
import { callGraphQL } from "./index";

import { API, graphqlOperation } from "aws-amplify";

export function useStoreSession(session: string, dependencyArray: [boolean]) {
	useEffect(() => {
		const fetchData = async () => {
			const vars: StoreSessionMutationVariables = { session: session };
			const response = await API.graphql(graphqlOperation(storeSession, vars));
			console.log(response);
		};
		fetchData();
	}, dependencyArray);
}