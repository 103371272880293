import { GraphQLResult } from "@aws-amplify/api-graphql";
import { useEffect, useState } from "react";
import {
    GetServicesFromAuthObjectIdsQuery,
    GetServicesFromAuthObjectIdsQueryVariables,
    Service,
    AuthInfo,
    ServiceConfiguration,
} from "../../graphql/API";
import { callGraphQL } from "./index";
import { getServicesFromAuthObjectIds } from "../../graphql/queries";

function mapServices(getServicesResult: GraphQLResult<GetServicesFromAuthObjectIdsQuery>): Service[] {
  const response = getServicesResult.data?.getServicesFromAuthObjectIds?.items?.filter((n) => n) || [];
  return response.map((service) => ({
    __typename: "Service",
    id: service?.id || "",
    serviceName: service?.serviceName || "",
    serviceClass: service?.serviceClass || "",
    appURL: service?.appURL || null,
    createdAt: service?.createdAt || null,
    idpArn: service?.idpArn || null,
    systemOwner: service?.systemOwner || "",
    costCenter: service?.costCenter || "",
    status: service?.status || null,
    region: service?.region || null,
    description: service?.description || null,
    aDGroupAuth: service?.aDGroupAuth || [],
    authInfo: service?.authInfo || null,
    serviceConfiguration: service?.serviceConfiguration || null,
  }));
}
export function useMyGetServices(aos: string) {
    const [services, setServices] = useState<Service[]>([]);
    const [error, setError] = useState<Error>();
    const [isLoading, setIsLoading] = useState(true);
  
    const fetchData = async () => {
      try {
        let nextToken: string | null | undefined = null;
        let page = 0;
        let servicesList: Service[] = [];
  
        while (nextToken !== null || page === 0) {
          const vars: GetServicesFromAuthObjectIdsQueryVariables = {
            ids: JSON.parse(aos),
            limit: 100,
            nextToken: nextToken,
          };
  
          const response = await callGraphQL<GetServicesFromAuthObjectIdsQuery>(
            getServicesFromAuthObjectIds,
            vars
          );
          //console.log("running through Serivces page", page);
          nextToken = response.data?.getServicesFromAuthObjectIds?.nextToken;
  
          servicesList.push(...mapServices(response));
  
          page++;
        }
  
        setServices(servicesList);
        console.log("Services:", servicesList);
      } catch (e) {
        console.log("ERROR", e);
        setError(e as Error);
      }
  
      setIsLoading(false);
    };
  
    useEffect(() => {
      fetchData();
    }, [aos]);
  
    return { services, isLoading, error, fetchData };
  }
  
