/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createServiceClass = /* GraphQL */ `
  mutation CreateServiceClass($input: CreateServiceClassInput) {
    createServiceClass(input: $input) {
      name
      version
      displayName
      description
      __typename
    }
  }
`;
export const deleteServiceClass = /* GraphQL */ `
  mutation DeleteServiceClass($input: DeleteServiceClassInput!) {
    deleteServiceClass(input: $input) {
      name
      version
      displayName
      description
      __typename
    }
  }
`;
export const createCloudAssembly = /* GraphQL */ `
  mutation CreateCloudAssembly($input: CreateCloudAssemblyInput) {
    createCloudAssembly(input: $input) {
      serviceClassName
      serviceClassVersion
      buildNumber
      displayName
      description
      __typename
    }
  }
`;
export const deleteCloudAssembly = /* GraphQL */ `
  mutation DeleteCloudAssembly($input: DeleteCloudAssemblyInput) {
    deleteCloudAssembly(input: $input) {
      serviceClassName
      serviceClassVersion
      buildNumber
      displayName
      description
      __typename
    }
  }
`;
export const createServiceAccount = /* GraphQL */ `
  mutation CreateServiceAccount($input: CreateServiceAccountInput) {
    createServiceAccount(input: $input) {
      name
      accountNumber
      accountRegion
      displayName
      description
      domainName
      domainNameServers
      hostedZoneId
      corpConnected
      __typename
    }
  }
`;
export const deleteServiceAccount = /* GraphQL */ `
  mutation DeleteServiceAccount($input: DeleteServiceAccountInput) {
    deleteServiceAccount(input: $input) {
      name
      accountNumber
      accountRegion
      displayName
      description
      domainName
      domainNameServers
      hostedZoneId
      corpConnected
      __typename
    }
  }
`;
export const createService = /* GraphQL */ `
  mutation CreateService($input: CreateServiceInput!) {
    createService(input: $input) {
      id
      serviceName
      serviceClass
      appURL
      createdAt
      idpArn
      systemOwner
      costCenter
      status
      region
      description
      aDGroupAuth
      authType
      authInfo {
        IdPName
        SAMLXML
        providerURL
        audience
        __typename
      }
      serviceConfiguration {
        targetAccount
        targetRegion
        accountDomain
        domainZoneId
        adminAdGroup
        editorAdGroup
        viewerAdGroup
        imageVersion
        grafanaPlugins
        minDashboardRefreshInterval
        minUnifiedAlertingInterval
        allowEmbedding
        azureAuthEnabled
        loginMaximumInactiveLifetimeDuration
        __typename
      }
      __typename
    }
  }
`;
export const updateService = /* GraphQL */ `
  mutation UpdateService($input: UpdateServiceInput!) {
    updateService(input: $input) {
      id
      serviceName
      serviceClass
      appURL
      createdAt
      idpArn
      systemOwner
      costCenter
      status
      region
      description
      aDGroupAuth
      authType
      authInfo {
        IdPName
        SAMLXML
        providerURL
        audience
        __typename
      }
      serviceConfiguration {
        targetAccount
        targetRegion
        accountDomain
        domainZoneId
        adminAdGroup
        editorAdGroup
        viewerAdGroup
        imageVersion
        grafanaPlugins
        minDashboardRefreshInterval
        minUnifiedAlertingInterval
        allowEmbedding
        azureAuthEnabled
        loginMaximumInactiveLifetimeDuration
        __typename
      }
      __typename
    }
  }
`;
export const deleteService = /* GraphQL */ `
  mutation DeleteService($input: DeleteServiceInput!) {
    deleteService(input: $input) {
      id
      serviceName
      serviceClass
      appURL
      createdAt
      idpArn
      systemOwner
      costCenter
      status
      region
      description
      aDGroupAuth
      authType
      authInfo {
        IdPName
        SAMLXML
        providerURL
        audience
        __typename
      }
      serviceConfiguration {
        targetAccount
        targetRegion
        accountDomain
        domainZoneId
        adminAdGroup
        editorAdGroup
        viewerAdGroup
        imageVersion
        grafanaPlugins
        minDashboardRefreshInterval
        minUnifiedAlertingInterval
        allowEmbedding
        azureAuthEnabled
        loginMaximumInactiveLifetimeDuration
        __typename
      }
      __typename
    }
  }
`;
export const createSystemRole = /* GraphQL */ `
  mutation CreateSystemRole($input: CreateSystemRoleInput!) {
    createSystemRole(input: $input) {
      id
      system
      roleName
      roleArn
      aDGroupAuth
      __typename
    }
  }
`;
export const updateSystemRole = /* GraphQL */ `
  mutation UpdateSystemRole($input: UpdateSystemRoleInput!) {
    updateSystemRole(input: $input) {
      id
      system
      roleName
      roleArn
      aDGroupAuth
      __typename
    }
  }
`;
export const deleteSystemRole = /* GraphQL */ `
  mutation DeleteSystemRole($input: DeleteSystemRoleInput!) {
    deleteSystemRole(input: $input) {
      id
      system
      roleName
      roleArn
      aDGroupAuth
      __typename
    }
  }
`;
export const createAuthObject = /* GraphQL */ `
  mutation CreateAuthObject($input: CreateAuthObjectInput!) {
    createAuthObject(input: $input) {
      id
      authType
      authName
      __typename
    }
  }
`;
export const updateAuthObject = /* GraphQL */ `
  mutation UpdateAuthObject($input: UpdateAuthObjectInput!) {
    updateAuthObject(input: $input) {
      id
      authType
      authName
      __typename
    }
  }
`;
export const deleteAuthObject = /* GraphQL */ `
  mutation DeleteAuthObject($input: DeleteAuthObjectInput!) {
    deleteAuthObject(input: $input) {
      id
      authType
      authName
      __typename
    }
  }
`;
export const associateAuthObjectandSystemRole = /* GraphQL */ `
  mutation AssociateAuthObjectandSystemRole(
    $input: AuthObjectandSystemRoleAssociationInput!
  ) {
    associateAuthObjectandSystemRole(input: $input)
  }
`;
export const dissociateAuthObjectandSystemRole = /* GraphQL */ `
  mutation DissociateAuthObjectandSystemRole(
    $input: AuthObjectandSystemRoleAssociationInput!
  ) {
    dissociateAuthObjectandSystemRole(input: $input)
  }
`;
export const associateAuthObjectandService = /* GraphQL */ `
  mutation AssociateAuthObjectandService(
    $input: AuthObjectandServiceAssociationInput!
  ) {
    associateAuthObjectandService(input: $input)
  }
`;
export const dissociateAuthObjectandService = /* GraphQL */ `
  mutation DissociateAuthObjectandService(
    $input: AuthObjectandServiceAssociationInput!
  ) {
    dissociateAuthObjectandService(input: $input)
  }
`;
export const startProvisioning = /* GraphQL */ `
  mutation StartProvisioning($input: StartProvisioningInput!) {
    startProvisioning(input: $input)
  }
`;
export const storeSession = /* GraphQL */ `
  mutation StoreSession($session: AWSJSON) {
    storeSession(session: $session)
  }
`;
