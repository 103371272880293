import React from "react";
import { Navigate, useLocation } from "react-router-dom";

export type PrivateRouteProps = {
    userInfo: any;
    children: React.ReactNode;
    outlet: JSX.Element
};

const PrivateRoute = ({ outlet, userInfo}: PrivateRouteProps) => {
    const location = useLocation();
    console.log(userInfo)

    return userInfo ? outlet : <Navigate to="/login" replace state={{ from: location }} />;
};

export default PrivateRoute;
