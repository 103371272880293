import { API, graphqlOperation } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { ReactComponent as ArrowIcon } from "../assets/icons/arrow.right.svg";
import ec2Instances from "../constants/ec2instances.json";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import GroupSelector from "./GroupSelector";
interface NewServiceFormProps {
    serviceId?: any;
    classes: any;
    accounts: any;
    userInfo: any;
    onCreated: () => void;
}

const NewSessionForm = ({ serviceId = null, classes, accounts, userInfo, onCreated }: NewServiceFormProps) => {
    const [activeServiceClass, setActiveServiceClass] = useState<null | string>(null);
    const [activeServiceClassVersion, setActiveServiceClassVersion] = useState<null | string>(null);
    const [loading, setLoading] = useState(false);
    const [cloudAssemblies, setCloudAssemblies] = useState<any[]>([]);
    const [adGroups, setAdGroups] = useState<string[]>([]);
    const [selectedAdGroups, setSelectedAdGroups] = useState<string[]>([]);
    const [selectedAdGroupsAdmin, setSelectedAdGroupsAdmin] = useState<string[]>([]);
    const [selectedAdGroupsEditor, setSelectedAdGroupsEditor] = useState<string[]>([]);
    const [selectedAdGroupsViewer, setSelectedAdGroupsViewer] = useState<string[]>([]);
    const [formError, setFormError] = useState<null | string>(null);
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
    

    const serviceNameInput = React.useRef<any>(null);
    const assemblyVersionInput = React.useRef<any>(null);
    const instanceSizeInput = React.useRef<any>(null);
    const serviceOwnerInput = React.useRef<any>(null);
    const regionUSInput = React.useRef<any>(null);
    const regionEUInput = React.useRef<any>(null);
    const gfImageVersionInput = React.useRef<any>(null);
    const gfPluginsInput = React.useRef<any>(null);
    const gfMinDashboardRefreshIntervalInput = React.useRef<any>(null);
    const gfMinUnifiedAlertingIntervalInput = React.useRef<any>(null);
    const [allowEmbedding, setAllowEmbedding] = React.useState("false");
    const handleAllowEmbeddingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAllowEmbedding(event.target.value);
    };

    const [azureAuthEnabled, setAzureAuthEnabled] = React.useState("false");
    const handleAzureAuthEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAzureAuthEnabled(event.target.value);
    };
    const gfLoginMaximumInactiveLifetimeDurationInput = React.useRef<any>(null);

    const accountInput = React.useRef<any>(null);
    const advOptionsInput = React.useRef<any>(null);

    useEffect(() => {
        if (userInfo["custom:groups"]) {
            setAdGroups(JSON.parse(userInfo["custom:groups"]));
        }
    }, [userInfo]);

    useEffect(() => {
        if (activeServiceClass && activeServiceClassVersion) {
            getcloudAssemblies(activeServiceClass, activeServiceClassVersion);
        }
    }, [activeServiceClass, activeServiceClassVersion]);

    const onFormSubmit = () => {
        setFormError(null);
        setLoading(true);
        if (validateInput()) {
            try {
                doProvisioning();
                onCreated();
            } catch (error: any) {
                console.log(error);
                setFormError(error);
            }
        }
        setLoading(false);
    };

    const getcloudAssemblies = async (serviceClassName: string, serviceClassVersion: string) => {
        setLoading(true);
        try {
            // Query using a parameter
            const response: any = await API.graphql({
                query: queries.listCloudAssemblies,
                variables: {
                    limit: 200,
                    serviceClassName: serviceClassName,
                    serviceClassVersion: serviceClassVersion,
                },
            });
            setLoading(false);
            const data = response.data["listCloudAssemblies"]["items"];
            const sorted = data.sort((a: any, b: any) => {
                const aParts = a.buildNumber.split('.').map(Number);
                const bParts = b.buildNumber.split('.').map(Number);
                for (let i = 0; i < aParts.length && i < bParts.length; i++) {
                    if (aParts[i] < bParts[i]) {
                        return -1;
                    } else if (aParts[i] > bParts[i]) {
                        return 1;
                    }
                }
                return aParts.length - bParts.length;
            }).reverse()
            setCloudAssemblies(sorted);
        } catch (error: any) {
            console.log("GRAPHQL ERROR");
            console.log(error.errors[0]);
            setLoading(false);
            setCloudAssemblies([]);
        }
    };

    const validateInput = () => {
        const serviceName = serviceNameInput.current.value;
        const serviceOwner = serviceOwnerInput.current.value;
        if (activeServiceClass !== "grafana") {
            const instanceSize = instanceSizeInput.current.value;
            if (!serviceName || !serviceOwner || !instanceSize || selectedAdGroups.length === 0) {
                setFormError("One or more fields are missing");
                return false;
            }
        } else {
            if (!gfImageVersionInput.current) {
                gfImageVersionInput["current"] = { value: "latest" };
            } else if (!gfImageVersionInput.current.value) {
                gfImageVersionInput["current"] = { value: "latest" };
            }
            if (!gfPluginsInput.current) {
                gfPluginsInput["current"] = { value: "" };
            } else if (!gfPluginsInput.current.value) {
                gfPluginsInput["current"] = { value: "" };
            }
            if (!gfMinDashboardRefreshIntervalInput.current) {
                gfMinDashboardRefreshIntervalInput["current"] = { value: "5s" };
            } else if (!gfMinDashboardRefreshIntervalInput.current.value) {
                gfMinDashboardRefreshIntervalInput["current"] = { value: "5s" };
            }
            if (!gfMinUnifiedAlertingIntervalInput.current) {
                gfMinUnifiedAlertingIntervalInput["current"] = { value: "10s" };
            } else if (!gfMinUnifiedAlertingIntervalInput.current.value) {
                gfMinUnifiedAlertingIntervalInput["current"] = { value: "10s" };
            }
            if (!gfLoginMaximumInactiveLifetimeDurationInput.current) {
                gfLoginMaximumInactiveLifetimeDurationInput["current"] = { value: "7d" };
            } else if (!gfLoginMaximumInactiveLifetimeDurationInput.current.value) {
                gfLoginMaximumInactiveLifetimeDurationInput["current"] = { value: "7d" };
            }
            if (
                !serviceName ||
                !serviceOwner ||
                selectedAdGroupsAdmin.length === 0 ||
                selectedAdGroupsEditor.length === 0 ||
                selectedAdGroupsViewer.length === 0
            ) {
                setFormError("One or more fields are missing");
                return false;
            }
            if (
                selectedAdGroupsAdmin.length !== 1 ||
                selectedAdGroupsEditor.length !== 1 ||
                selectedAdGroupsViewer.length !== 1
            ) {
                setFormError("Only 1 AD group per role is allowed");
                return false;
            }
        }
        if (!validateServiceName()) {
            return false;
        }
        if (!userInfo["cost-center"]) {
            setFormError("Internal error, cost-center not part of token");
            return false;
        }
        if (!assemblyVersionInput.current.value) {
            setFormError("No assemblyVersion selected");
        }
        return true;
    };

    const validateServiceName = () => {
        console.log("Validating service name");
        const serviceName = serviceNameInput.current.value;
        if (activeServiceClass !== "grafana") {
            if (serviceName.match(/[-a-z0-9]{0,12}/g)[0] !== serviceName) {
                setFormError("The service name does not comply with naming rules.......");
                return false;
            } else {
                setFormError("");
                return true;
            }
        } else {
            if (serviceName.match(/[a-z0-9]{0,12}/g)[0] !== serviceName) {
                setFormError("The service name does not comply with naming rules.......");
                return false;
            } else {
                setFormError("");
                return true;
            }
        }
    };

    const doProvisioning = async () => {
        var provisioningInput: { [k: string]: any } = {};
        const serviceName = serviceNameInput.current.value;
        const serviceOwner = serviceOwnerInput.current.value;
        provisioningInput = {
            costCenter: userInfo["cost-center"],
            serviceOwner: serviceOwner,
            serviceName: serviceName,
            assemblyVersion: assemblyVersionInput.current.value,
            serviceClassName: activeServiceClass,
            serviceClassVersion: activeServiceClassVersion,
        };

        if (activeServiceClass !== "grafana") {
            const region = regionEUInput.current.checked ? "EU" : "US";
            const instanceSize = instanceSizeInput.current.value;
            provisioningInput["region"] = region;
            provisioningInput["ec2Type"] = instanceSize;
            provisioningInput["adGroups"] = selectedAdGroups;
        } else {
            const imageVersion = gfImageVersionInput.current.value;
            const plugins = gfPluginsInput.current.value;
            const minDashboardRefreshInterval = gfMinDashboardRefreshIntervalInput.current.value;
            const minUnifiedAlertingInterval = gfMinUnifiedAlertingIntervalInput.current.value;
            const loginMaximumInactiveLifetimeDuration = gfLoginMaximumInactiveLifetimeDurationInput.current.value;
            const account = accountInput.current.value;
            const accountNumber = account.split(",")[0];
            const accountDomain = account.split(",")[2];
            const domainZoneId = account.split(",")[3];
            let region = "";
            if (account.split(",")[1] === "eu-central-1") {
                region = "EU";
            } else if (account.split(",")[1] === "us-east-1") {
                region = "US";
            }
            provisioningInput["region"] = region;
            provisioningInput["adGroups"] = selectedAdGroupsAdmin.concat(
                selectedAdGroupsEditor.concat(selectedAdGroupsViewer)
            );
            provisioningInput["serviceConfiguration"] = {
                targetAccount: accountNumber,
                accountDomain: accountDomain,
                domainZoneId: domainZoneId,
                adminAdGroup: selectedAdGroupsAdmin[0],
                editorAdGroup: selectedAdGroupsEditor[0],
                viewerAdGroup: selectedAdGroupsViewer[0],
                imageVersion: imageVersion,
                grafanaPlugins: plugins,
                minDashboardRefreshInterval: minDashboardRefreshInterval,
                minUnifiedAlertingInterval: minUnifiedAlertingInterval,
                allowEmbedding: allowEmbedding,
                azureAuthEnabled: azureAuthEnabled,
                loginMaximumInactiveLifetimeDuration: loginMaximumInactiveLifetimeDuration,
            };
        }
        return await API.graphql(
            graphqlOperation(mutations.startProvisioning, {
                input: provisioningInput,
            })
        );
    };

    const onSelectVersion = (e: any) => {
        setActiveServiceClassVersion(e.target.value);
    };

    const onSelectedADGroups = (items: string[]) => {
        setSelectedAdGroups(items);
        console.log(items);
    };

    const onSelectedADGroupsAdmin = (items: string[]) => {
        setSelectedAdGroupsAdmin(items);
        console.log(items);
    };

    const onSelectedADGroupsEditor = (items: string[]) => {
        setSelectedAdGroupsEditor(items);
        console.log(items);
    };

    const onSelectedADGroupsViewer = (items: string[]) => {
        setSelectedAdGroupsViewer(items);
        console.log(items);
    };

    const onSelectedAdvancedOptions = (items: any) => {
        setShowAdvancedOptions(advOptionsInput.current.checked);
    };

    if (loading) {
        return (
            // Loading spinner
            <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 mt-6 ml-6 w-12"></div>
            </div>
        );
    }

    if (activeServiceClass) {
        if (!activeServiceClassVersion) {
            return (
                // Version selector
                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                    <div className="px-4 sm:px-6">
                        <h4>Select version</h4>
                    </div>
                    <div className="mt-6 relative flex-1 px-4 sm:px-6">
                        <div className="absolute inset-0 px-4 sm:px-6">
                            <div className="grid grid-cols-1 gap-5">
                                <label className="block">
                                    <span>Version</span>
                                    <select
                                        defaultValue=""
                                        onChange={onSelectVersion}
                                        className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    >
                                        <option value="" disabled hidden>
                                            Select..
                                        </option>
                                        {classes.map((serviceClass: any) => {
                                            if (serviceClass.name === activeServiceClass) {
                                                return (
                                                    <option value={serviceClass.version} key={serviceClass.version}>
                                                        {serviceClass.version}
                                                    </option>
                                                );
                                            } else {
                                                return "";
                                            }
                                        })}
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            // Both serviceclass and version has been set
            if (cloudAssemblies.length === 0) {
                return (
                    // No configuration available
                    <div className="h-full p-4 flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                        <p>No configuration available for the selected service</p>
                    </div>
                );
            }
            return (
                // New service form
                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                    <div className="px-4 sm:px-6">
                        <h4>Create service</h4>
                        <p className="-mt-5">
                            {cloudAssemblies[0].displayName} {activeServiceClassVersion}
                        </p>
                    </div>
                    <div className="mt-6 relative flex-1 px-4 sm:px-6">
                        <div className="absolute inset-0 px-4 sm:px-6">
                            <div className="grid grid-cols-1 gap-5">
                                <label className="block">
                                    <span>Name</span>
                                    <div className="help inline-block">
                                        <span className="block bg-novo-blue text-gray-50 text-center ml-2 w-5 text-sm rounded-3xl opacity-75">
                                            ?
                                        </span>
                                        <span className="tooltip bg-gray-800 text-gray-50 text-sm px-3 py-1 rounded top-6 w-52">
                                            Name can contain only lower case alphanumeric characters and hyphens. It
                                            must start with an alphabetic character and can't be longer than 12
                                            characters.
                                        </span>
                                    </div>
                                    <input
                                        type="text"
                                        ref={serviceNameInput}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                        placeholder=""
                                        onChange={validateServiceName}
                                    />
                                </label>

                                <label className="block">
                                    <span>Build number</span>
                                    <select
                                        ref={assemblyVersionInput}
                                        className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    >
                                        {cloudAssemblies.map((assembly: any) => {
                                            return (
                                                <option key={assembly.buildNumber} value={assembly.buildNumber}>
                                                    {assembly.buildNumber}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </label>
                                {activeServiceClass !== "grafana" && (
                                    <label className="block">
                                        <span>Instance size</span>
                                        <select
                                            ref={instanceSizeInput}
                                            className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                        >
                                            '
                                            {ec2Instances.map((instance: any) => {
                                                return (
                                                    <option key={instance.name} value={instance.name}>
                                                        {instance.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </label>
                                )}
                                {activeServiceClass !== "grafana" && (
                                    <span>
                                        <span>AD Groups</span>
                                        <GroupSelector items={adGroups} onSelected={onSelectedADGroups} />
                                    </span>
                                )}
                                {activeServiceClass === "grafana" && (
                                    <span>
                                        <span>Admin AD Group</span>
                                        <GroupSelector items={adGroups} onSelected={onSelectedADGroupsAdmin} />
                                    </span>
                                )}
                                {activeServiceClass === "grafana" && (
                                    <span>
                                        <span>Editor AD Groups</span>
                                        <GroupSelector items={adGroups} onSelected={onSelectedADGroupsEditor} />
                                    </span>
                                )}
                                {activeServiceClass === "grafana" && (
                                    <span>
                                        <span>Viewer AD Groups</span>
                                        <GroupSelector items={adGroups} onSelected={onSelectedADGroupsViewer} />
                                    </span>
                                )}
                                <label className="block">
                                    <span>Service Owner</span>
                                    <input
                                        type="text"
                                        ref={serviceOwnerInput}
                                        className="mt-1 block w-full rounded-md border-gray-300 bg-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                        placeholder=""
                                        value={userInfo.email.split("@")[0]}
                                        disabled
                                    />
                                </label>
                                {activeServiceClass !== "grafana" && (
                                    <div className="block">
                                        <span>Region</span>
                                        <div>
                                            <label className="inline-flex items-center">
                                                <input
                                                    ref={regionEUInput}
                                                    id="region"
                                                    name="region"
                                                    type="radio"
                                                    className="rounded-full border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                                    defaultChecked
                                                />
                                                <span className="ml-2">EU</span>
                                            </label>
                                        </div>
                                        <div>
                                            <label className="inline-flex items-center">
                                                <input
                                                    id="region"
                                                    ref={regionUSInput}
                                                    name="region"
                                                    type="radio"
                                                    className="rounded-full border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                                />
                                                <span className="ml-2">US</span>
                                            </label>
                                        </div>
                                    </div>
                                )}
                                {activeServiceClass === "grafana" && (
                                    <label className="block">
                                        <span>Account</span>
                                        <select
                                            ref={accountInput}
                                            className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                        >
                                            {accounts.map((account: any) => {
                                                console.log(account);
                                                return (
                                                    <option
                                                        key={account.name}
                                                        value={[
                                                            account.accountNumber,
                                                            account.accountRegion,
                                                            account.domainName,
                                                            account.hostedZoneId,
                                                        ]}
                                                    >
                                                        {account.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </label>
                                )}
                                {activeServiceClass === "grafana" && (
                                    <div>
                                        <label className="inline-flex items-center">
                                            <input
                                                ref={advOptionsInput}
                                                type="checkbox"
                                                onChange={onSelectedAdvancedOptions}
                                                className="rounded-full border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                            />
                                            <span className="ml-2">Show Advanced Options</span>
                                        </label>
                                    </div>
                                )}
                                {showAdvancedOptions && activeServiceClass === "grafana" && (
                                    <label className="block">
                                        <span>Image Version</span>
                                        <div className="help inline-block">
                                            <span className="block bg-novo-blue text-gray-50 text-center ml-2 w-5 text-sm rounded-3xl opacity-75">
                                                ?
                                            </span>
                                            <span className="tooltip bg-gray-800 text-gray-50 text-sm px-3 py-1 rounded top-6 w-52">
                                                Docker image version to be installed
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            ref={gfImageVersionInput}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                            placeholder="latest"
                                        />
                                    </label>
                                )}
                                {showAdvancedOptions && activeServiceClass === "grafana" && (
                                    <label className="block">
                                        <span>Plugins</span>
                                        <div className="help inline-block">
                                            <span className="block bg-novo-blue text-gray-50 text-center ml-2 w-5 text-sm rounded-3xl opacity-75">
                                                ?
                                            </span>
                                            <span className="tooltip bg-gray-800 text-gray-50 text-sm px-3 py-1 rounded top-6 w-52">
                                                Comma separated plugins, that should be installed on Grafana
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            ref={gfPluginsInput}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                            placeholder="plugin1,plugin2,plugin3"
                                        />
                                    </label>
                                )}
                                {showAdvancedOptions && activeServiceClass === "grafana" && (
                                    <label className="block">
                                        <span>Min Dashboard Refresh Interval</span>
                                        <div className="help inline-block">
                                            <span className="block bg-novo-blue text-gray-50 text-center ml-2 w-5 text-sm rounded-3xl opacity-75">
                                                ?
                                            </span>
                                            <span className="tooltip bg-gray-800 text-gray-50 text-sm px-3 py-1 rounded top-6 w-52">
                                                Ex: 5s,10s,1m,15m
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            ref={gfMinDashboardRefreshIntervalInput}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                            placeholder="5s (default)"
                                        />
                                    </label>
                                )}                     
                                {showAdvancedOptions && activeServiceClass === "grafana" && (
                                    <label className="block">
                                        <span>Min Unified Alerting Interval</span>
                                        <div className="help inline-block">
                                            <span className="block bg-novo-blue text-gray-50 text-center ml-2 w-5 text-sm rounded-3xl opacity-75">
                                                ?
                                            </span>
                                            <span className="tooltip bg-gray-800 text-gray-50 text-sm px-3 py-1 rounded top-6 w-52">
                                                Ex: 10s,30s,1m,2h
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            ref={gfMinUnifiedAlertingIntervalInput}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                            placeholder="10s (default)"
                                       />
                                    </label>
                                )}
                                {showAdvancedOptions && activeServiceClass === "grafana" && (
                                    <label className="block">
                                        <span> Max Login Inactive Lifetime Duration</span>
                                        <div className="help inline-block">
                                            <span className="block bg-novo-blue text-gray-50 text-center ml-2 w-5 text-sm rounded-3xl opacity-75">
                                                ?
                                            </span>
                                            <span className="tooltip bg-gray-800 text-gray-50 text-sm px-3 py-1 rounded top-6 w-52">
                                                Ex: 5m,6h,5d,2w,1M
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            ref={gfLoginMaximumInactiveLifetimeDurationInput}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                            placeholder="7d (default)"
                                       />
                                    </label>
                                )}
                                {showAdvancedOptions && activeServiceClass === "grafana" && (
                                <fieldset className="block">
                                    <legend className="block">
                                    <span>Allow Security Embedding</span>
                                    <div className="help inline-block">
                                        <span className="block bg-novo-blue text-gray-50 text-center ml-2 w-5 text-sm rounded-3xl opacity-75">
                                        ?
                                        </span>
                                        <span className="tooltip bg-gray-800 text-gray-50 text-sm px-3 py-1 rounded top-6 w-52">
                                        Set to true to enable the embedding of Grafana dashboards, for more flexibility in sharing your data visualizations.
                                        </span>
                                    </div>
                                    </legend>
                                    <div className="mt-1">
                                    <label className="inline-flex items-center">
                                        <input
                                        type="radio"
                                        name="allowEmbedding"
                                        className="form-radio"
                                        value="false"
                                        checked={allowEmbedding === "false"}
                                        onChange={handleAllowEmbeddingChange}
                                        />
                                        <span className="ml-2">False (default)</span>
                                    </label>
                                    <label className="inline-flex items-center ml-6">
                                        <input
                                        type="radio"
                                        name="allowEmbedding"
                                        className="form-radio"
                                        value="true"
                                        checked={allowEmbedding === "true"}
                                        onChange={handleAllowEmbeddingChange}
                                        />
                                        <span className="ml-2">True</span>
                                    </label>
                                    </div>
                                </fieldset>
                                )}
                                {showAdvancedOptions && activeServiceClass === "grafana" && (
                                <fieldset className="block">
                                    <legend className="block">
                                    <span>Enable Azure AD Authentication</span>
                                    <div className="help inline-block">
                                        <span className="block bg-novo-blue text-gray-50 text-center ml-2 w-5 text-sm rounded-3xl opacity-75">
                                        ?
                                        </span>
                                        <span className="tooltip bg-gray-800 text-gray-50 text-sm px-3 py-1 rounded top-6 w-52">
                                        Set to true to enable Azure AD authentication.
                                        </span>
                                    </div>
                                    </legend>
                                    <div className="mt-1">
                                    <label className="inline-flex items-center">
                                        <input
                                        type="radio"
                                        name="azureAuthEnabled"
                                        className="form-radio"
                                        value="false"
                                        checked={azureAuthEnabled === "false"}
                                        onChange={handleAzureAuthEnabledChange}
                                        />
                                        <span className="ml-2">False (default)</span>
                                    </label>
                                    <label className="inline-flex items-center ml-6">
                                        <input
                                        type="radio"
                                        name="azureAuthEnabled"
                                        className="form-radio"
                                        value="true"
                                        checked={azureAuthEnabled === "true"}
                                        onChange={handleAzureAuthEnabledChange}
                                        />
                                        <span className="ml-2">True</span>
                                    </label>
                                    </div>
                                </fieldset>
                                )}
                                
                                
                                <p className="text-red-600">{formError}</p>
                                <button
                                    onClick={() => {
                                        onFormSubmit();
                                    }}
                                    className="bg-novo-blue p-4 mt-3 py-2 rounded-full text-center text-novo-white mb-8"
                                >
                                    Create service
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    } else {
        if (classes) {
            const serviceClassList: any[] = [];

            return (
                // Service class list
                <div className="h-full flex flex-col py-6 bg-novo-blue text-white shadow-xl overflow-y-scroll">
                    <div className="px-4 sm:px-6">
                        <h4 className="text-white">Create service</h4>
                        <p className="-mt-5">{}</p>
                    </div>
                    <div className="mt-10 relative flex-1 px-4 sm:px-6">
                        <div className="absolute inset-0 px-4 sm:px-6">
                            <div className="grid grid-cols-1 gap-5">
                                {classes.map((serviceClass: any) => {
                                    let displayName = null;
                                    console.log(serviceClass.name);
                                    switch (serviceClass.name) {
                                        case "rstudio-server":
                                            displayName = "RStudio Server";
                                            break;
                                        case "rstudio-connect":
                                            displayName = "RStudio Connect";
                                            break;
                                        case "jupyterlab":
                                            displayName = "JupyterLab";
                                            break;
                                        case "grafana":
                                            displayName = "Grafana";
                                            break;
                                    }
                                    if (displayName && !serviceClassList.includes(displayName)) {
                                        serviceClassList.push(displayName);
                                        return (
                                            <div
                                                key={serviceClass.name}
                                                onClick={() => setActiveServiceClass(serviceClass.name)}
                                                className="cursor-pointer text-2xl relative"
                                            >
                                                {displayName} <ArrowIcon className="right-0 top-1 absolute" />
                                            </div>
                                        );
                                    } else {
                                        return false;
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                // Loading services
                <div className="h-full flex flex-col py-6 bg-novo-blue text-white shadow-xl overflow-y-scroll">
                    <div className="px-4 sm:px-6">
                        <h4 className="text-white">Create service</h4>
                        <p className="-mt-5">{}</p>
                    </div>
                    <div className="mt-10 relative flex-1 px-4 sm:px-6">
                        <div className="absolute inset-0 px-4 sm:px-6">
                            <div className="grid grid-cols-1 gap-5">Loading services..</div>
                        </div>
                    </div>
                </div>
            );
        }
    }
};

export default NewSessionForm;
