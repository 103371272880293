import { Auth, Hub, Amplify } from "aws-amplify";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import Callback from "./components/Callback";
import PrivateRoute from "./components/PrivateRoute";
import Services from "./screens/Services";
import Signin from "./screens/Signin";

const App = () => {
    const [amplifyConfigured, setAmplifyConfigured] = useState(false);
    const [token, setToken] = useState<null | string>(null);
    const [userInfo, setUserInfo] = useState<any | null>(null);
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        console.log("sds");

        if (!amplifyConfigured) {
            configureAmplify();
        }

        Hub.listen("auth", ({ payload: { event, data } }) => {
            switch (event) {
                // -- Amplify configured -- //
                case "configured":
                    setAmplifyConfigured(true);
                    break;
                // -- Doing an active callback flow -- //
                case "parsingCallbackUrl":
                    break;
                // -- After cognito signin flow -- //
                case "signIn":
                case "cognitoHostedUI":
                    getToken();
                    break;
                // -- After cognito signout flow -- //
                case "oAuthSignOut":
                    console.log("oAuthSignOut", data);
                    break;
                case "signOut":
                    console.log("signOut", data);
                    setToken(null);
                    setUserInfo(null);
                    break;
                // -- Error doing cognito login flow -- //
                case "signIn_failure":
                    console.log("signIn_failure", data);
                    break;
                case "cognitoHostedUI_failure":
                    console.log("Sign in failure", data);
                    setErrorMsg("Sign in failure: " + data);
                    break;
                // -- Token and refresh token has expired - log out -- //
                case "tokenRefresh_failure":
                    doSignOut();
                    break;
                default:
                    console.log(`default event: ${event}`);
                    console.log(`default event data`, data);
            }
        });
    }, [amplifyConfigured]);

    useEffect(() => {
        if (!token) {
            getToken();
        }
    }, [token, amplifyConfigured]);

    async function getToken() {
        return Auth.currentSession()
            .then((session) => {
                if (session.isValid()) {
                    const userToken = session.getIdToken().getJwtToken();
                    if (userToken) {
                        setToken(userToken);
                        setUserInfo({ ...jwt_decode(userToken), ...{ idToken: userToken } });
                    }
                } else {
                    setToken(null);
                    setUserInfo(null);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const configureAmplify = async () => {
        let bootstrapEndpoint = `https://bootstrap.${window.location.host}`;
        console.log("bootstrap endpoint", bootstrapEndpoint);

        if (window.location.host === "localhost:3000") {
            bootstrapEndpoint = `http://${window.location.host}/bootstrap.localhost.json`;
        }
        await fetch(bootstrapEndpoint)
            .then((response) => response.json())
            .then((response) => {
                const apiProps = {
                    graphql_headers: async () => ({
                        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
                    }),
                };

                Amplify.configure({ Auth: response.Auth, API: { ...response.API, ...apiProps } });
            })
            .catch((error: any) => {
                console.log(error);
                setErrorMsg("Failed to get config");
                throw new Error("Failed to get config");
            });
    };

    async function authLogout() {
        try {
            await Auth.signOut();
        } catch (error: any) {
            console.log("error signing out: ", error);
            setErrorMsg("Error signing out:" + error);
        }
    }

    const doSignOut = () => {
        authLogout();
    };

    if (amplifyConfigured) {
        return (
            <Routes>
                <Route
                    path="/"
                    element={
                        <Signin
                            errorMsg={errorMsg}
                            setErrorMsg={setErrorMsg}
                            userInfo={userInfo}
                            amplifyConfigured={amplifyConfigured}
                        />
                    }
                ></Route>
                <Route
                    path="/services"
                    element={
                        <PrivateRoute
                            userInfo={userInfo}
                            outlet={
                                <Services userInfo={userInfo} doSignOut={doSignOut} />
                            }
                        >
                            <Services userInfo={userInfo} doSignOut={doSignOut} />
                        </PrivateRoute>
                    }
                ></Route>

                <Route path="/login/callback" element={<Callback userInfo={userInfo} />}></Route>
                <Route path="*" element={<Navigate to="/" />}></Route>
            </Routes>
        );
    } else {
        return <Callback userInfo={null} />;
    }
};

export default App;
