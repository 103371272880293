import { FC, ReactElement, useState } from "react";
import Info from "../components/AlertOverlay/Info";
import AlertOverlay from "../components/AlertOverlay/Overlay";
import Features from "../components/Features";
import Header from "../components/Header";
import Menu from "../components/Menu";
import MyRoles from "../components/MyRoles";
import NewServiceForm from "../components/NewServiceForm";
import UpdateServiceForm from "../components/UpdateServiceForm";
import ServiceItem from "../components/ServiceItem";
import SlideOverlay from "../components/SlideOverlay";
import { useGetServiceClasses, useMyGetServices, useGetServiceAccounts, useStoreSession } from "../hooks/api";
import { useGetServices } from "../hooks/api/useGetServices";

interface ServicesProps {
    doSignOut: () => void;
    userInfo: any;
}

const Services: FC<ServicesProps> = ({ doSignOut, userInfo }: ServicesProps): ReactElement => {
    const [showNewOverlay, setShowNewOverlay] = useState(false);
    const [showUpdateOverlay, setShowUpdateOverlay] = useState(false);
    const [showNewServiceCreated, setShowNewServiceCreated] = useState(false);
    const [showRolesOverlay, setShowRolesOverlay] = useState(false);
    const [showFeaturesOverlay, setShowFeaturesOverlay] = useState(false);
    const [activeService, setActiveService] = useState<null | object>(null);
    const { services, isLoading: serviceLoading, fetchData: fetchMyServices } = useMyGetServices(userInfo.aos);
    const { serviceClasses } = useGetServiceClasses();
    const { serviceAccounts } = useGetServiceAccounts();
    const uniqueServiceIds = new Set();

    // HACK - this needs to be called to store the access token! :p
    //useGetServices(userInfo.aos);

    // This is the future solution - a dedicated backend session storage
    useStoreSession(JSON.stringify({ services: services }), [serviceLoading]);

    const onServiceCreated = () => {
        setShowNewOverlay(false);
        setShowNewServiceCreated(true);
    };

    return (
        <div className="bg-gray-100 flex">
            <Menu />
            <div className="relative w-full flex flex-col h-screen overflow-y-hidden">
                <Header
                    name={`${userInfo?.given_name} ${userInfo?.family_name}`}
                    openMyRoles={() => setShowRolesOverlay(true)}
                    openFeatures={() => setShowFeaturesOverlay(true)}
                    doSignOut={doSignOut}
                />
                <div className="w-full h-screen overflow-x-hidden border-t flex flex-col">
                    <main className="w-full flex-grow p-6">
                        <h2 className="">Services</h2>

                        <div className="relative">
                            <button
                                onClick={() => {
                                    setShowNewOverlay(true);
                                }}
                                className="bg-novo-blue p-4 py-2  rounded-full text-center absolute top-0 right-0 text-novo-white"
                            >
                                New service
                            </button>
                        </div>
                        <button
                            onClick={() => fetchMyServices()}
                            className="align-middle cursor-pointer text-novo-light-blue underline "
                        >
                            Refresh
                        </button>

                        <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 grid-rows-2 gap-3 mt-8 max-w-8xl min-w-6xl">
                        {services.map((service) => {
                            if (!uniqueServiceIds.has(service.id)) {
                            uniqueServiceIds.add(service.id);
                            return (
                                <ServiceItem
                                service={service}
                                key={service.id}
                                userInfo={userInfo}
                                overlay={setShowUpdateOverlay}
                                setService={setActiveService}
                                />
                            );
                            }
                            return null;
                        })}
                        {serviceLoading && (
                            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 mt-14 ml-6  w-12 mb-4"></div>
                        )}
                        </div>

                    </main>
                </div>

                <SlideOverlay isOpen={showNewOverlay} onClose={() => setShowNewOverlay(false)}>
                    <NewServiceForm
                        userInfo={userInfo}
                        classes={serviceClasses}
                        accounts={serviceAccounts}
                        onCreated={() => onServiceCreated()}
                    />
                </SlideOverlay>

                <SlideOverlay isOpen={showUpdateOverlay} onClose={() => setShowUpdateOverlay(false)}>
                    <UpdateServiceForm
                        userInfo={userInfo}
                        service={activeService}
                        accounts={serviceAccounts}
                        onCreated={() => onServiceCreated()}
                    />
                </SlideOverlay>

                <SlideOverlay isOpen={showRolesOverlay} onClose={() => setShowRolesOverlay(false)}>
                    <MyRoles roles={userInfo["cognito:roles"]} />
                </SlideOverlay>

                <SlideOverlay isOpen={showFeaturesOverlay} onClose={() => setShowFeaturesOverlay(false)}>
                    <Features features={[]} />
                </SlideOverlay>

                <AlertOverlay isOpen={showNewServiceCreated} onClose={() => setShowNewServiceCreated(false)}>
                    <Info
                        title="Service created"
                        message="It will take approximately 10-15 minutes for the service to be provisioned, Please wait.."
                        buttonText="Close"
                        onClick={() => setShowNewServiceCreated(false)}
                    />
                </AlertOverlay>
            </div>
        </div>
    );
};

export default Services;
