import { FC, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

interface CallbackProps {
    userInfo: any;
}

const Callback: FC<CallbackProps> = ({ userInfo }) => {
    let history = useNavigate();
    useEffect(() => {
        let timeoutTimer = setTimeout(() => {
            // Navigate to login page if token have not been established within 10 seconds
            history("/login");
        }, 10000);

        return () => {
            clearTimeout(timeoutTimer);
        };
    }, [history]);

    if (userInfo) {
        return <Navigate to="/services" />;
    }
    return (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-novo-blue flex flex-col items-center justify-center">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
            <h2 className="text-center text-white text-xl font-semibold">Loading...</h2>
            <p className="w-1/3 text-center text-white">Generating permission matrix. Please wait.</p>
        </div>
    );
};

export default Callback;
