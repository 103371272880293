import { GraphQLResult } from "@aws-amplify/api-graphql";
import { useEffect, useState } from "react";
import { ListServiceClassesQuery, ServiceClass } from "../../graphql/API";
import { listServiceClasses } from "../../graphql/queries";
import { callGraphQL } from "./index";

function mapServiceClasses(
    listServiceResult: GraphQLResult<ListServiceClassesQuery>): ServiceClass[] {
    const response = listServiceResult.data?.listServiceClasses?.items?.filter((n) => n);

    return (
        response!.map(
            (serviceClass) =>
                ({
                    name: serviceClass?.name,
                    version: serviceClass?.version,
                    description: serviceClass?.description,
                    displayName: serviceClass?.displayName,
                } as ServiceClass)
        ) || []
    );
}

export function useGetServiceClasses() {
    const [error, setError] = useState<Error>();
    const [isLoading, setIsLoading] = useState(false);
    const [serviceClasses, setServiceClasses] = useState<ServiceClass[]>([]);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const data = await callGraphQL<ListServiceClassesQuery>(listServiceClasses);
            setServiceClasses(mapServiceClasses(data));
        } catch (e) {
            setError(e as Error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);
    return { serviceClasses, isLoading, error, fetchData };
}
