import { Dialog, Transition } from "@headlessui/react";
import { FC } from "react";

interface AlertOverlayProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const AlertOverlay: FC<AlertOverlayProps> = ({ isOpen, onClose, children }) => {
    return (
        <Transition show={isOpen}>
            <Dialog static className="fixed z-10 inset-0 overflow-y-auto" onClose={() => null}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        enter="ease-in-out duration-200"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in-out duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        {children}
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

export default AlertOverlay;
