import { useCombobox, useMultipleSelection } from "downshift";
import React, { useState } from "react";

interface GroupSelectorProps {
    items: string[];
    onSelected: (items: string[]) => void;
}

const GroupSelector = ({ items, onSelected }: GroupSelectorProps) => {
    const [inputValue, setInputValue] = useState<string>("");
    const { getDropdownProps, addSelectedItem, removeSelectedItem, selectedItems } = useMultipleSelection<string>({
        onSelectedItemsChange: ({ selectedItems }) => {
            console.log("onSelectedItemsChange", selectedItems);
            onSelected(selectedItems || []);
        },
    });

    const getFilteredItems = (items: string[]) => {
        return items.filter(
            (item) => selectedItems.indexOf(item) < 0 && item.toLowerCase().startsWith(inputValue.toLowerCase())
        );
    };

    const { isOpen, getToggleButtonProps, getMenuProps, getInputProps, getItemProps, selectItem } =
        useCombobox({
            inputValue,
            items: getFilteredItems(items),
            onStateChange: ({ inputValue, type, selectedItem }) => {
                switch (type) {
                    case useCombobox.stateChangeTypes.InputChange:
                        setInputValue(inputValue || "");
                        console.log("InputChange", selectedItems);
                        break;
                    case useCombobox.stateChangeTypes.InputKeyDownEnter:
                    case useCombobox.stateChangeTypes.ItemClick:
                    case useCombobox.stateChangeTypes.InputBlur:
                        if (selectedItem) {
                            setInputValue("");
                            addSelectedItem(selectedItem);
                            selectItem("");
                        }
                        break;
                    default:
                        break;
                }
            },
        });

    return (
        <div>
            <div className="autcomplete">
                <div className="w-full flex flex-col items-center mx-auto">
                    <div className="w-full">
                        <div className="flex flex-col items-center relative">
                            <div className="w-full ">
                                <div
                                    data-testid="selected-item-list"
                                    className="my-2 p-1 flex border mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 "
                                >
                                    <div className="flex flex-auto flex-wrap">
                                        {selectedItems.map((selectedItem, index) => {
                                            return (
                                                <div
                                                    data-testid="selected-item"
                                                    key={index}
                                                    className="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-teal-700 bg-teal-100 border border-teal-300 "
                                                >
                                                    <div className="text-xs font-normal leading-none max-w-full flex-initial">
                                                        {selectedItem}
                                                    </div>
                                                    <div className="flex flex-auto flex-row-reverse">
                                                        <div onClick={() => removeSelectedItem(selectedItem)}>
                                                            <svg
                                                                data-testid={selectedItem + "-removal"}
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="100%"
                                                                height="100%"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                className="feather feather-x cursor-pointer hover:text-teal-400 rounded-full w-4 h-4 ml-2"
                                                            >
                                                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                                                <line x1="6" y1="6" x2="18" y2="18"></line>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        <div className="flex-1">
                                            <input
                                                className="bg-transparent p-1 px-2 appearance-none outline-none h-full w-full text-gray-800"
                                                {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200"
                                    >
                                        <button
                                            data-testid="toggleButton"
                                            className="cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none"
                                            {...getToggleButtonProps()}
                                        >
                                            <svg
                                                transform={isOpen ? "" : "rotate(180)"}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="100%"
                                                height="100%"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="feather feather-chevron-up w-4 h-4"
                                            >
                                                <polyline points="18 15 12 9 6 15"></polyline>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div {...getMenuProps()}>
                            {isOpen && (
                                <div
                                    data-testid="filtered-items-list"
                                    className="absolute shadow top-100 bg-white z-40 w-96 lef-0 rounded max-h-select overflow-x-hidden overflow-y-scroll h-40 "
                                >
                                    <div className="flex flex-col w-full">
                                        {getFilteredItems(items).map((item, index) => (
                                            <div
                                                className="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-teal-100"
                                                key={`${item}${index}`}
                                                {...getItemProps({ item, index })}
                                            >
                                                <div
                                                    data-testid="filtered-item"
                                                    className="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-teal-100"
                                                >
                                                    <div className="w-full items-center flex">
                                                        <div className="mx-2 leading-6  ">{item}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GroupSelector;
