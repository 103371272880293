import React from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as CloudIcon } from "../assets/icons/icon.cloud.svg";
import { ReactComponent as Logo } from "../assets/images/logo.small.svg";

const Menu = () => {
    return (
        <aside className="relative bg-novo-blue h-screen w-32 sm:block shadow-xl">
            <div className="p-6">
                <Logo className="m-auto" />
                <span className="absolute rounded-md bg-gray-900 text-yellow-100 py-2 px-6 top-16 left-5 transform -rotate-12 text-2xl">
                    ALPHA
                </span>
            </div>
            <nav className="text-white text-base font-semibold pt-3">
                <NavLink
                    to="/services"
                    className="text-white text-center opacity-75 hover:opacity-100 py-6 pl-3 pr-3 nav-item block cursor-pointer"
                    style={{ opacity: "100%" }}
                >
                    <CloudIcon className="m-auto my-1.5" />
                    Services
                </NavLink>
            </nav>
        </aside>
    );
};

export default Menu;
